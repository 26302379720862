<template>
	<div class="zoom" :class="{ active: zoomStore.zoom.zoomImageUrl }" aria-hidden="true">
		<PinchScrollZoom ref="zoomer" centred :min-zoom="0.75" translate3d :width="fullscreen.width" :height="fullscreen.height">
			<img class="image" :src="image" alt="zoomableImage"/>
		</PinchScrollZoom>
		<MaoButton aria-hidden="true" @close="closeZoom" variant="text-button-opacity-light" class="zoom-button" icon="close" />
	</div>
</template>

<script>
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import MaoButton from "./MaoButton.vue";
import { useZoomStore } from "@/stores/zoom";

export default {
	components: {
		MaoButton,
		PinchScrollZoom,
	},
	setup() {
		const zoomStore = useZoomStore();
		return { zoomStore };
	},
	data() {
		return {
			loading: false,
			fullscreen: {
				width: window.innerWidth,
				height: window.innerHeight,
			},
		};
	},
	watch: {
		async image() {
			if (this.image) {
				this.loading = true;
				// laod the image and await

				this.$refs.zoomer.setData({
					scale: 0.75,
					originX: 150,
					originY: 200,
					translateX: -100,
					translateY: -50,
				});
				await new Promise((resolve) => {
					const img = new Image();
					img.onload = () => {
						resolve();
					};
					img.src = this.image;
				});
				this.loading = false;
			}
		},
	},
	computed: {
		image() {
			if (this.zoomStore.zoom.zoomImageUrl) {
				return this.zoomStore.zoom.zoomImageUrl;
			}
			return "";
		},
	},
	methods: {
		closeZoom() {
			this.zoomStore.setZoomImage("");
		},
	},
};
</script>

<style scoped lang="scss">
.zoom {
	position: fixed;
	inset: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
	pointer-events: none;
	opacity: 0;
	transition: 0.3s all;
	backdrop-filter: blur(6px) brightness(0.3);
	&.active {
		opacity: 1;
		pointer-events: all;
	}
	.image {
		position: absolute;
		transform: translateY(-50%);
		transition: opacity 0.3s;
		opacity: 1;

		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		&.loading {
			opacity: 0;
		}
	}
	.zoom-button {
		position: fixed;
		top: var(--padding-l);
		right: var(--padding-l);
	}
}
</style>

<template>
	<div class="image-wrap grid-x">
		<div
			class="image"
			:tabindex="tabindex"
			:class="{
				loading: loading,
				coverImage: coverImage,
				fullheight: fullheight,
				['aspect-ratio-' + parsedAspectRatio]: aspectRatio?? true
			}"
			:style="style"
			:alt="alt"
		></div>
		<MaoButton @open="openZoom" v-if="fullImage" variant="text-button-opacity-light" class="zoom-button" tabindex="-1" :style="zoomButtonPosition" icon="zoom_out_map" />
	</div>
</template>

<script>
import MaoButton from "./MaoButton.vue";
import { useZoomStore } from "@/stores/zoom";

export default {
	components: {
		MaoButton,
	},
	props:['coverImage', 'aspectRatio','url','tabindex','alt','fullimage','pointView','zoomButtonPosition','extra'],
	setup() {
		const zoomStore = useZoomStore();
		return { zoomStore };
	},
	data() {
		return {
			loading: false,
			realAspectRatio: '1/1',
		};
	},
	computed: {

		parsedAspectRatio() {
			if(this.aspectRatio) {
				return this.aspectRatio.replace('/', '-');
			}
			return '4-5';
		},

		style() {
			let style = {
				backgroundImage: `url(${this.url})`,
			};

			//style['aspect-ratio'] = this.aspectRatio;

			if (this.pointView) {
				style.backgroundPosition = this.pointView.left + "% " + this.pointView.top + "%";
			}

			if (this.extra) {
				style = Object.assign(style, this.extra);
			}

			return style;
		},
	},
	methods: {
		openZoom() {
			this.zoomStore.setZoomImage(this.fullImage);
		},
	},
	mounted() {
		if (this.url) {
			this.loading = true;
			const img = new Image();
			img.onload = () => {
				this.loading = false;
			};
			img.src = this.url;
			this.realAspectRatio = img.width / img.height;
		}
	},
};
</script>

<style scoped lang="scss">
.backdrop-image {
	inset: -2rem;
    z-index: -3;
    filter: blur(11px);
	position: fixed;
}
.image-wrap {
	border-radius: var(--border-radius-m);
	overflow: clip;
	position: relative;
	.image {
		width: 100%;
		height: 50vh;
		/* aspect-ratio: 4/5; */
		background-size: cover;
		@include breakpoint(s) {
			background-size: 100%;
			
			&:not(.fullheight) {
				height: auto;
			}
		}

		&.aspect-ratio-1-1 {
			aspect-ratio: 1/1;
		}

		&.aspect-ratio-4-5 {
			aspect-ratio: 4/5;
		}
		
		&.aspect-ratio-5-4 {
			aspect-ratio: 5/4;
		}

		&.aspect-ratio-16-9 {
			aspect-ratio: 16/9;
		}

		position: relative;
		background-position: center center;
		opacity: 1;
		transition: 0.3s all;
		grid-column: window;

		background-repeat: no-repeat;
		background-size: cover !important;

		&.coverImage {
			background-size: cover;
		}
		
		&.loading {
			opacity: 0;
		}
	}
	.zoom-button {
		position: absolute;
		right: 1rem;
		bottom: 1rem;
		z-index: 1;
	}
	.zoom {
		position: fixed;
		inset: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		pointer-events: none;
		opacity: 0;
		transition: 0.3s all;
		&.active {
			opacity: 1;
			pointer-events: all;
		}
	}
}
</style>

<template>
	<Teleport to="body">
		<div class="overlay" :class="{ active: status }" @click="emitClick"></div>
	</Teleport>
</template>

<script>
	export default {
		emits: ["close"],
		props: {
			status: {
				type: Boolean,
				default: false
			}
		},
		methods: {
			emitClick() {
				this.$emit("close");
			}
		}
	};
</script>

<style scoped lang="scss">
	.overlay {
		backdrop-filter: blur(6px) brightness(0.3);
		position: fixed;
		inset: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s;
		&.active {
			opacity: 1;
			pointer-events: all;
		}
	}
</style>

<template>
	<div :class="{ 'chip-icon-text': true, 'has-text': hasText, dark: !mode, [mode]: true, filled: filled }" :aria-label="ariaLabel"
		role="group">
		<div v-if="icon" :class="`icon-${icon}`" tabindex="-1" aria-hidden="true" role="img"
			:aria-label="ariaIconLabel"></div>
		<div v-if="label" class="text label-big" role="text">{{ labels['Floor'] }}</div>
	</div>
</template>

<script>
import { useAppDataStore } from "@/stores/appData";

export default {
	props: [
		'icon',
		'label',
		'mode',
		'ariaIconLabel',
		'filled'
	],
	setup() {
		const appDataStore = useAppDataStore();
		return { appDataStore };
	},
	computed: {
		labels() {
			return this.appDataStore.appData.labels;
		},
		ariaLabel() {
			if (this.icon && this.icon == 'stairs') {
				return this.labels['Floor'] + ' ' + this.label;
			}
			else if (this.label) {
				return this.label;
			} else if (this.icon) {
				return this.labels[this.icon];
			}
			return '';
		},
		hasText() {
			return !!this.label;
		}
	}
};
</script>

<style scoped lang="scss">
.chip-icon-text {
	display: inline-flex;
	padding: var(--padding-none);
	align-items: center;
	align-content: center;
	gap: var(--padding-xs);
	flex-wrap: wrap;
	border-radius: var(--border-radius-s);
	align-items: flex-end;
	
	&.filled {
		flex-wrap: nowrap;
		align-items: flex-start;
		background-color: var(--surface-opacity-high-light);
		padding: var(--padding-s);
	}

	&.has-text {
		@include generateVariants((dark: (color: var(--text-on-surface-standard-opacity-dark))));
	}

	[class^="icon-"] {
		font-size: var(--icon-size-xs);
	}
}
</style>

<template>

	<div class="stepper navigation-enabled">
		<MaoButton @decrement="decrement" variant="secondary" :status="status == 'start-position'? 'standby' : ''" icon="arrow_back_ios_new" :aria-label="labels['Previous Artwork']"  />
		<div class="artwork-number">
			<div class="button-text body-small">{{labels['Artwork']}} {{ currentStep }}/{{ end }}</div>
		</div>	
		<MaoButton @increment="increment" variant="secondary" :status="status == 'end-position'? 'standby' : ''" icon="arrow_forward_ios" :aria-label="labels['Next Artwork']" />
	</div>

</template>

<script>
	import { useAppDataStore } from "@/stores/appData";

	import MaoButton from "./MaoButton.vue";
	export default {
		components: {
			MaoButton
		},
		setup() {
			const appDataStore = useAppDataStore();
			return { appDataStore };
		},
		props: {
			label: {
				type: String,
				default: "Opera"
			},
			start: {
				type: Number,
				default: 1
			},
			end: {
				type: Number,
				default: 1
			},
			current: {
				type: Number,
				default: 1
			}
		},
		data() {
			return {
				currentStep: this.current? this.current : this.start,
			}
		},
		computed: {
			labels() {
				return this.appDataStore.appData.labels;
			},
			status: function() {
				let value = '';

				if(this.currentStep === this.start) {
					value = 'start-position';
				}

				if(this.currentStep === this.end) {
					value = 'end-position';
				}

				return value;
			}
		},
		methods: {
			decrement() {
				if(this.currentStep > this.start) {
					this.currentStep--;
					this.$emit('decrement');
				}
				if(this.currentStep == this.start) {
					this.$emit('startPosition');
				}
			},
			increment() {
				if(this.currentStep < this.end) {
					this.currentStep++;
					this.$emit('increment');
				}
				if(this.currentStep == this.end) {
					this.$emit('endPosition');
				}
			}
		},
		mounted() {
			if(this.currentStep == this.end) {
				this.$emit('endPosition');
			}
			if(this.currentStep == this.start) {
				this.$emit('startPosition');
			}
		}
	}
</script>
<style scoped lang="scss">
.stepper {
	display: flex;
	padding: var(--padding-s);
	justify-content: space-between;
	align-items: center;
	border-radius: var(--border-radius-s);
	background: var(--surface-contrast-container-high);
	width: 100%;

	::selection {
		background: transparent;
	}

	.artwork-number {
		display: flex;
		align-items: center;
		gap: var(--padding-none);
		.button-text {
			color: var(--text-on-surface-invert);
			text-align: center;
		}
	}
}
</style>
<template>
	<div class="loader" :class="{loading:loading}"></div>
</template>

<script>
import { useGlobalActionsStore } from "@/stores/globalActions";

export default {
	setup() {
		const globalActionsStore = useGlobalActionsStore();
		return { globalActionsStore };
	},
	computed: {
		loading() {
			return this.globalActionsStore.loading;
		}
	}
};
</script>

<style scoped lang="scss">
.loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	opacity: 0;
	transition: all 1s;
	pointer-events: none;
	background-position: center center;
    background-size: 5rem;
    background-repeat: no-repeat;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Ccircle cx='50' cy='50' fill='none' stroke='white' stroke-width='2' r='35' stroke-dasharray='164.93361431346415 56.97787143782138'%3E%3CanimateTransform attributeName='transform' type='rotate' repeatCount='indefinite' dur='1s' values='0 50 50;360 50 50' keyTimes='0;1'%3E%3C/animateTransform%3E%3C/circle%3E%3C/svg%3E");
	&.loading {
		backdrop-filter: blur(5px);
		opacity: 1;
		pointer-events: all;
	}
}
</style>

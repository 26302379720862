<template>
	<div class="exhibition-card" :aria-label="'Exhibition: ' + exhibition.title" tabindex="0" @click="openUrl"
		role="button">
		<MaoLabel class="label-button label-big" :label="labels['Exhibition']" />
		<img class="image" :src="exhibition.image.large" :alt="exhibition.title" />
		<h3 class="title title-big">{{ exhibition.title }}</h3>
		<MaoChipIconText v-if="exhibition['formatted-date']" :aria-label="durationLabel" :icon="'history_toggle_off'"
			:label="durationLabel" />
		<div class="description body-small" style="font-size: 0.8888888889rem !important; line-height: 1.3999999762 !important;" v-html="exhibition.description"></div>
		<MaoButton tabindex="0" @click="openUrl" :label="labels['Show Exhibition']" />
	</div>
</template>

<script>
import MaoButton from "./MaoButton.vue";
import MaoLabel from "./MaoLabel.vue";
import MaoChipIconText from "./MaoChipIconText.vue";
import { useAppDataStore } from "@/stores/appData";

export default {
	components: {
		MaoChipIconText,
		MaoButton,
		MaoLabel
	},
	props: {
		exhibition: {
			type: Object,
			required: true
		}
	},
	setup() {
		const appDataStore = useAppDataStore();
		return { appDataStore };
	},
	methods: {
		openUrl() {
			window.open(this.exhibition.url, "_blank");
		}
	},
	computed: {
		labels() {
			return this.appDataStore.appData.labels;
		},
		durationLabel() {
			return this.exhibition["formatted-date"].start + " - " + this.exhibition["formatted-date"].end;
		}
	}
};
</script>

<style scoped lang="scss">
.exhibition-card {
	display: flex;
	align-items: flex-start;
	outline-offset: 9px;
	flex-direction: column;
	gap: 1rem;
	position: relative;
	cursor: pointer;
	min-height: 30rem;

	.label-button {
		position: absolute;
		left: 1rem;
		top: 1rem;
		border-radius: var(--border-radius-l);
	}

	.description {
		overflow: hidden;
		text-overflow: ellipsis;
		min-height: 5.7rem;
		color: var(--text-on-surface-variant-high);
	}

	.image {
		aspect-ratio: 3/2;
		object-fit: cover;
		object-position: center center;
		border-radius: var(--border-radius-l);
	}
}
</style>

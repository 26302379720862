<template>
	<div>
		<span v-for="(part, index) in parsedContent" :key="index">
			<span v-if="part.isTerm" class="special-word" @click="openAlert(part.word)">
				{{ part.word }}
			</span>
			<span v-else>
				{{ part.text }}
			</span>
		</span>
	</div>
	<MaoAlert background="var(--surface-secondary-container)" color="var(--text-on-secondary-container)" :title="appDataStore.appData.labels['Glossary']" :status="alertStatus" @close="closeAlert">
		<p class="body-small" style="color: var(--text-on-secondary-container)" v-html="currentDefinition"></p>
	</MaoAlert>
</template>

<script>
import MaoAlert from "./MaoAlert.vue";
import { useAppDataStore } from "@/stores/appData";

export default {
	components: {
		MaoAlert
	},
	props: ['content'],
	data() {
		return {
			alertStatus: false,
			currentDefinition: ""
		};
	},
	setup() {
		const appDataStore = useAppDataStore();
		return { appDataStore };
	},
	computed: {
		parsedContent() {
			let contentParts = [];
			let lastIndex = 0;
			
			// Trova i termini nel contenuto e li separa
			this.appDataStore.appData.glossary.forEach(word => {
				const regex = new RegExp(`\\b(${word.word})\\b`, 'gi');
				let match;

				while ((match = regex.exec(this.content)) !== null) {
					// Aggiungi il testo che precede il termine trovato
					if (match.index > lastIndex) {
						contentParts.push({ text: this.content.slice(lastIndex, match.index), isTerm: false });
					}

					// Aggiungi il termine trovato
					contentParts.push({ word: match[1], isTerm: true });

					// Aggiorna l'indice di riferimento
					lastIndex = regex.lastIndex;
				}
			});

			// Aggiungi il testo restante dopo l'ultimo termine
			if (lastIndex < this.content.length) {
				contentParts.push({ text: this.content.slice(lastIndex), isTerm: false });
			}

			return contentParts;
		}
	},
	methods: {
		openAlert(word) {
			const termData = this.appDataStore.appData.glossary.find(t => t.word.toLowerCase() === word.toLowerCase());
			if (termData) {
				this.currentDefinition = termData.description;
				this.alertStatus = true;
			}
		},
		closeAlert() {
			this.alertStatus = false;
			this.currentDefinition = "";
		}
	},
	
};
</script>

<style scoped lang="scss">
.special-word {
	text-decoration: underline;
	cursor: pointer;
	text-decoration-thickness: 0.1rem;
	text-underline-offset: 0.2rem;

}
</style>

<template>
	<hr class="separator" role="separator" aria-orientation="horizontal" />
</template>

<script>
export default {

};
</script>

<style scoped lang="scss">
.separator {
	display: block;
	width: 30rem;
	max-width: 90%;
	margin: 2rem auto;
	background-color: var(--surface-opacity-high-dark);
	height: 0.3rem;
	align-self: baseline;
	border: 0;
	outline: 0;
	border-radius: 1rem;
}
</style>
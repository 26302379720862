<template>
	<div class="bottom-menu" v-if="status" :class="{active: status, 'end-of-page': endOfPage}">
		<nav class="grid-x">
			<MaoNavigationMenu v-if="component == 'navigationMenu'" aria-label="Navigation Menu"/>
			<MaoButton v-if="component == 'button'" class="menu-button" :label="button.label" @click="button.action" variant="secondary" />
			<MaoStepper v-if="component == 'stepper'" :start="stepper.start" :current="stepper.current" @increment="stepper.increment" @decrement="stepper.decrement" :end="stepper.end" :label="stepper.label" />
		</nav>
	</div>
</template>

<script>
import MaoNavigationMenu from "./../components/MaoNavigationMenu.vue";
import MaoStepper from "./../components/MaoStepper.vue";
import MaoButton from "./../components/MaoButton.vue";
import { useBottomMenuStore } from "@/stores/bottomMenu.js";

export default {
	components: {
		MaoNavigationMenu,
		MaoStepper,
		MaoButton
	},
	setup() {
		const bottomMenuStore = useBottomMenuStore();
		return { bottomMenuStore };
	},
	data() {
		return {
			endOfPage: false
		}
	},
	computed: {
		status() {
			return this.bottomMenuStore.bottomMenuStatus;
		},
		component() {
			return this.bottomMenuStore.bottomMenuComponent;
		},
		stepper() {
			return this.bottomMenuStore.stepper;
		},
		button() {
			return this.bottomMenuStore.button;
		}
	},
	methods: {
		onScroll() {
			let offset = 40;
			if (window.innerWidth > 768) {
				offset = 0;
			}

			if (window.innerHeight + window.scrollY >= document.body.offsetHeight - offset) {
				this.endOfPage = true;
			} else {
				this.endOfPage = false;
			}
		}
	},
	mounted() {
		//window.addEventListener("scroll", this.onScroll);, fa sparire la bottom bar senza farla riapparire in ihpone perché riduce la viewport
	}
}
</script>
<style scoped lang="scss">
.loading {
	position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
	aspect-ratio: 1;
	width: 2rem;
	height: 2rem;
	opacity: 0;
	transition: opacity 0.5s;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Ccircle cx='50' cy='50' fill='none' stroke='white' stroke-width='10' r='35' stroke-dasharray='164.93361431346415 56.97787143782138'%3E%3CanimateTransform attributeName='transform' type='rotate' repeatCount='indefinite' dur='1s' values='0 50 50;360 50 50' keyTimes='0;1'%3E%3C/animateTransform%3E%3C/circle%3E%3C/svg%3E");
	&.is-loading {
		opacity: 1;
	}
}
.bottom-menu {
	position: fixed;
	bottom: 2.8vh;
	left: 0;
	right: 0;
	z-index: 2;
	opacity: 0;
	transition: opacity 0.3s;
	pointer-events: none;
    max-width: 550px;
    margin: auto;
	
	.menu-button {
		border-radius: var(--border-radius-s) !important;
	}
	
	&.active {
		opacity: 1;
		pointer-events: all;
	}

	&.end-of-page {
		opacity: 0;
	}

	& > * {
		min-height: 3.2rem;
	}

	.grid-x {
		& > * {
			box-shadow: 0rem 0.25rem 0.25rem 0rem rgba(0,0,0,0.3);
			grid-column-start: col-2-start;
			grid-column-end: col-11-end;
			
			&.button {
				justify-content: center;
				padding: var(--padding-s);
				border-radius: var(--border-radius-xs);
				@include generateButtonStates(
					(
						pressed: (
							border-radius: var(--border-radius-s)
						)
					)
				);
			}
		}
	}
}
</style>
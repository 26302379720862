<template>
	<div class="app-container" :class="bodyClass">

		<div class="screen-pre-tutorial" :class="{active: !screenPreTutorialLoaded }"></div>

		<MaoHeader v-if="loaded" />

		<MaoBottomMenu />
		<MaoFullDataLoader @onLoaded="dataLoaded" />
		<router-view v-slot="{ Component, route }">
			
			<transition name="fade" mode="out-in">
				<component :is="Component" :key="route.path" v-if="Component && loaded" />
			</transition>

		</router-view>
		<div class="skip-transition"></div>
		<MaoZoom />
		<MaoAudioPlayer />
		<MaoLoader />
		<transition name="fade" mode="out-in">
			<MaoFooter v-if="loaded"  />
		</transition>
	</div>
</template>

<script>
	import MaoHeader from "@/components/MaoHeader.vue";
	import MaoFullDataLoader from "@/components/MaoFullDataLoader.vue";
	import MaoBottomMenu from "@/components/MaoBottomMenu.vue";
	import MaoZoom from "@/components/MaoZoom.vue";
	import MaoAudioPlayer from "@/components/MaoAudioPlayer.vue";
	import MaoLoader from "@/components/MaoLoader.vue";
	import MaoFooter from "@/components/MaoFooter.vue";
	import { RouterView } from "vue-router";
	import { useGlobalActionsStore } from "@/stores/globalActions";
	import { defineComponent, watch } from "vue";

	export default defineComponent({
		name: "App",
		components: {
			RouterView,
			MaoHeader,
			MaoFullDataLoader,
			MaoBottomMenu,
			MaoZoom,
			MaoAudioPlayer,
			MaoLoader,
			MaoFooter
		},
		setup() {
			const globalActionsStore = useGlobalActionsStore();

			watch(
				() => globalActionsStore.preventScrolling,
				newVal => {
					if (newVal) {
						document.body.classList.add("prevent-scrolling");
					} else {
						document.body.classList.remove("prevent-scrolling");
					}
				},
				{ immediate: true }
			);

			return { globalActionsStore };
		},
		methods: {
			dataLoaded() {
				this.loaded = true;
				setTimeout(() => {
					this.screenPreTutorialLoaded = true;
				}, 1000);

			}
		},
		computed: {
			preventScrolling() {
				return this.globalActionsStore.preventScrolling;
			}
		},
		watch: {
			$route() {
				if (this.$route.name == "PathArtwork") {
					this.bodyClass = "dark-background";
				} else {
					this.bodyClass = "";
				}

			}
		},
		mounted() {
			const link = document.createElement("link");
			link.href = process.env.VUE_APP_FIGMA_CSS_URL + "?v=figma-css-" + Math.random().toString(36).substring(7);
			link.type = "text/css";
			link.rel = "stylesheet";
			document.head.appendChild(link);

			// Controlla se il tutorial è stato già visualizzato
			const tutorialCompleted = localStorage.getItem("tutorialCompleted");
			if (!tutorialCompleted) {
				
				let currentLang = 'it';
				if(this.$route.params.lang) {
					currentLang = this.$route.params.lang;
				}
				else if(window.location.pathname.split('/')[1]) {
					currentLang = window.location.pathname.split('/')[1];
				}

				this.$router.push({name: 'Tutorial', params: { lang: currentLang } });
			}

		},
		data() {
			return {
				loaded: false,
				bodyClass: "",
				screenPreTutorialLoaded: false
			};
		}
	});
</script>

<style>
	html, body {
		height: 100%;
		overflow-y: scroll;
	}

	body {
		position: fixed;
		width: 100%;
		overflow-y: scroll;
	}

	.app-container {
		min-height: 100vh;
	}
	.app {
	}
	.prevent-scrolling {
		overflow: hidden;
	}
	body {
		&::-webkit-scrollbar {
			width: 3px;
		}
	}
	main {
		min-height: calc(100vh - 5.3rem);
	}

</style>
<style lang="scss" scoped>
	.dark-background {
		background-color: var(--surface-background-variant-dark);
	}
</style>

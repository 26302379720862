import { defineStore } from "pinia";

export const useGlobalActionsStore = defineStore("useGlobalActionsStore", {
	// State
	state: () => ({
		preventScrolling: false,
		header: true,
		loading: false,
		headerException: false,
		prevPath: false,
	}),

	actions: {
		setPreventScrolling(preventScrolling) {
			this.preventScrolling = preventScrolling;
		},
		setHeader(status) {
			this.preventScrolling = !status;
			this.header = status;
			this.headerException = false;
			this.prevPath = false;
		},
		setLoading(status) {
			this.headerException = false;
			this.loading = status;
			this.prevPath = false;
		},
		setHeaderException(status) {
			this.headerException = status;
			this.prevPath = false;
		},
		showHeader() {
			this.header = true;
		},
		hideHeader() {
			this.header = false;
		},
		setPrevPath(path) {
			this.prevPath = path;
		}
	}
});

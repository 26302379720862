import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const defaultLang = 'it'; // Lingua predefinita

const routes = [
	{
		path: "/:lang/",
		name: "Home",
		component: HomeView
	},
	{
		path: "/:lang/paths",
		name: "Paths",
		component: () => import("../views/PathsView.vue")
	},
	{
		path: "/:lang/explore",
		name: "Explore",
		component: () => import("../views/ExploreView.vue")
	},
	{
		path: "/:lang/path/:id",
		name: "Path",
		component: () => import("../views/PathView.vue")
	},
	{
		path: "/:lang/path/:id/:artworkId",
		name: "PathArtwork",
		component: () => import("../views/PathArtworkView.vue")
	},
	{
		path: "/:lang/artworks",
		name: "Artworks",
		component: () => import("../views/ArtworksView.vue")
	},
	{
		path: "/:lang/gallery/:id",
		name: "Gallery",
		component: () => import("../views/GalleryView.vue")
	},
	{
		path: "/:lang/artwork/:id",
		name: "Artwork",
		component: () => import("../views/ArtworkView.vue")
	},
	{
		path: "/:lang/tutorial",
		name: "Tutorial",
		component: () => import("../views/TutorialView.vue")
	},
	{
		path: "/",
		redirect: `/${defaultLang}` // Reindirizzamento alla lingua predefinita
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: `/${defaultLang}/:pathMatch(.*)*` // Reindirizza percorsi non validi con lingua predefinita
	}
];

const router = createRouter({
	history: createWebHistory(process.env.VUE_APP_BASE_URL),
	routes,
	scrollBehavior() {
		// Scroll body
		const body = document.querySelector("body");
		if (body) {
			body.scrollTop = 0;
		}
		return { top: 0 };
	}
});

// Aggiungere il parametro lang se non è presente
router.beforeEach((to, from, next) => {
	// Controlla se il parametro `lang` è presente
	if (!to.params.lang) {
		// Aggiungi la lingua predefinita se non esiste
		next({
			...to,
			params: { ...to.params, lang: defaultLang }
		});
	} else {
		// Continua con la navigazione
		next();
	}
});

export default router;

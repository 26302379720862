<template>
	<Teleport to="body">
		<MaoOverlay :status="status" @close="stopAndClose" />
		<transition name="fade">
			<div class="audio-player" :class="{active: status}" v-if="status">
				<MaoButton class="close-button-audio-player" variant="text-button-opacity-light" icon="close" @close="stopAndClose" />
				
				<div class="audio-header">
					<div class="title body-big-Semibold">{{ title }}</div>
				</div>
				<div class="audio-content">
					
					<div class="actions">
						<MaoButton
							class="replay-button"
							@click="replay10"
							icon="replay_10"
							variant="secondary" />
						<MaoButton
							class="play-button"
							@click="onPlayButtonClick"
							:icon="playing ? 'pause' : 'play_arrow'"
							variant="secondary" />
						<MaoButton
							class="forward-button"
							@click="forward10"
							icon="forward_10"
							variant="secondary" />
					</div>

					<div class="audio-bar-container">
						<div class="current-time label-big">{{ formatAudioLength(current_time) }}</div>
						<div class="audio-bar" @click="setProgress">
							<div class="progress-bar" :style="{ width: getProgressBarWidth() + '%' }">
								<div
									class="time-dot"
									v-show="hover_active || dragging"
									:style="{ left: hoverPosition + '%' }"
									@mousedown="startDrag"
									@touchstart.prevent="startDrag"></div>
							</div>
						</div>
						<div class="end-time label-big">{{ formatAudioLength(audio_length) }}</div>
					</div>
					<audio
						ref="audio_player"
						v-if="audio && audio.url"
						:src="audio.url"
						@loadedmetadata="audioLoaded"
						@timeupdate="updateCurrentTime"
						hidden>
					</audio>

				</div>
				<div class="audio-footer">
					<div class="transcription"  :class="{active: transcriptionActive}" v-if="audio && audio['audio-transcription']">
						<div class="transcription-header" @click="transcriptionActive = !transcriptionActive">
							<div class="label-big">{{ labels['Transcription'] }}</div>
							<MaoButton
								@toggle="transcriptionActive = !transcriptionActive"
								:icon="transcriptionActive ? 'collapse_content' : 'expand_content'"
								variant="text-button-opacity-light" />
						</div>
						<div class="transcription-content">
							<div class="transcription-html" v-html="audio['audio-transcription']"></div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</Teleport>
</template>

<script>
	import { defineComponent, watch } from "vue";
	import { useAppDataStore } from "@/stores/appData";
	import MaoButton from "./MaoButton.vue";
	import MaoOverlay from "./MaoOverlay.vue";

	export default defineComponent({
		emits: ["close"],
		components: {
			MaoButton,
			MaoOverlay
		},
		setup() {
			const appDataStore = useAppDataStore();
			return { labels: appDataStore.appData.labels };
		},
		props: {
			title: {
				type: String,
				default: "Audio Player"
			},
			audio: {
				type: Object,
			},
			status: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				current_time: 0,
				playing: false,
				audio_player: null,
				audio_length: 0,
				hover_active: false,
				hoverPosition: 0,
				dragging: false,
				transcriptionActive: false
			};
		},
		mounted() {
			this.audio_player = this.$refs.audio_player;

			document.addEventListener("mousemove", this.dragProgress);
			document.addEventListener("mouseup", this.stopDrag);
			document.addEventListener("touchmove", this.dragProgress, { passive: false });
			document.addEventListener("touchend", this.stopDrag);

			watch(
				() => this.playing,
				newVal => {
					if (newVal && this.audio_player) {
						this.audio_player.play();
					} else if (this.audio_player) {
						this.audio_player.pause();
					}
				}
			);
		},
		methods: {
			stopAndClose() {
				this.playing = false;
				this.$emit("close");
			},
			formatAudioLength(length_in_seconds) {
				const minutes = Math.floor(length_in_seconds / 60);
				let seconds = length_in_seconds % 60;
				seconds = Math.floor(seconds);
				return seconds < 10 ? `${minutes}:0${seconds}` : `${minutes}:${seconds}`;
			},
			onPlayButtonClick() {
				this.playing = !this.playing;
			},
			getProgressBarWidth() {
				const progress = (this.current_time / this.audio_length) * 100;
				return Math.min(Math.max(progress, 0), 100);
			},
			audioLoaded() {
				if (this.audio_player) {
					this.audio_length = this.audio_player.duration;
				}
			},
			updateCurrentTime() {
				if (this.audio_player) {
					this.current_time = this.audio_player.currentTime;
				}
			},
			setProgress(event) {
				const progressBar = event.currentTarget;
				if (!progressBar) {
					return;
				}
				const isTouchEvent = event.type === "touchstart";
				const clientX = isTouchEvent ? (event).touches[0].clientX : (event).clientX;
				const progressBarElement = progressBar;
				const clickX = clientX - progressBarElement.getBoundingClientRect().left; // Calculate click position within the element
				const progressBarWidth = progressBarElement.clientWidth; // Width of the progressBar
				const clickTime = (clickX / progressBarWidth) * this.audio_length; // Calculate time to seek
				if (this.audio_player) {
					this.audio_player.currentTime = clickTime;
				}
			},
			replay10() {
				if (this.audio_player) {
					this.audio_player.currentTime = Math.max(this.audio_player.currentTime - 10, 0);
				}
			},
			forward10() {
				if (this.audio_player) {
					this.audio_player.currentTime = Math.min(this.audio_player.currentTime + 10, this.audio_length);
				}
			},
			/* onHoverProgress(event) {
				if (this.dragging) return;
				this.hover_active = true;
				if(!this.$el && !(this.$el && this.$el.querySelector(".audio-bar"))) return;
				const progressBar = this.$el.querySelector(".audio-bar");
				const progressBarRect = progressBar.getBoundingClientRect();
				if (event.type === "mousemove") {
					const hoverX =
						(((event).clientX - progressBarRect.left) / progressBar.clientWidth) * 100;
					this.hoverPosition = hoverX;
				} else {
					const touch = (event).touches[0];
					const hoverX = ((touch.clientX - progressBarRect.left) / progressBar.clientWidth) * 100;
					this.hoverPosition = hoverX;
				}
			}, 
			clearHover() {
				if (this.dragging) return;
				this.hover_active = false;
			},
			*/
			startDrag(event) {
				event.preventDefault();
				this.dragging = true;
			},
			dragProgress(event) {
				if (!this.dragging) return;
				event.preventDefault();
				this.setProgress(event);
			},
			stopDrag() {
				this.dragging = false;
			}
		},
		beforeUnmount() {
			if (this.audio_player) {
				this.audio_player.pause();
				this.audio_player = null;
			}
		}
	});
</script>

<style lang="scss" scoped>
	
	.audio-player {
		position: fixed;
		bottom: 0;
		display: flex;
		width: 100%;
		align-items: center;
		flex-direction: column;
		gap: var(--gap-l);
		background: var(--surface-contrast-container-high);
		padding-top: var(--padding-l);
		border-top-left-radius: var(--border-radius-m);
		border-top-right-radius: var(--border-radius-m);
		color: var(--text-on-surface-invert);
		transition: all 0.5s;
		z-index: 3;
		transform: translateY(200%);
		
		&.active {
			display: flex;
			animation: slide-up 0.5s forwards;
		}

		@keyframes slide-up {
			0% {
				transform: translateY(200%);
			}
			100% {
				transform: translateY(0);
			}
		}

		.close-button-audio-player {
			position: absolute;
			top: -3.5rem;
			right: 1rem;
		}
		
		.audio-bar-container {
			display: flex;
			align-items: center;
			gap: 0.5rem;
		}

		.audio-header {
			width: 100%;
			.title {
				text-align: center;
			}
		}
		.audio-content {
			width: 100%;
			gap: var(--gap-l);
			display: flex;
			flex-direction: column;
			padding-inline: var(--padding-l);
			.current-time,
			.end-time {
				line-height: 1.2;
			}
	
			.actions {
				display: flex;
				gap: var(--gap-xl);
				justify-content: center;
				align-items: center;
				.replay-button,
				.forward-button {
					background: var(--surface-opacity-high-light);
					border-radius: 100%;
				}
				.play-button {
					.icon-play_arrow {
						font-size: var(--icon-size-xl);
					}
				}
			}
			
			.audio-bar {
				display: flex;
				height: 0.375rem;
				padding-right: var(--padding-s);
				flex-direction: column;
				align-items: flex-start;
				gap: 0.625rem;
				flex: 1 0 0;
				border-radius: var(--border-radius-s);
				background: var(--surface-opacity-high-light);
				position: relative;
				cursor: pointer;
	
				.progress-bar {
					display: flex;
					width: 0%;
					height: 100%;
					background: var(--surface-opacity-low-light);
					border-radius: var(--border-radius-s);
					position: relative;
					transition: 0.2s all;
	
					.time-dot {
						position: absolute;
						top: 0;
						left: 0;
						width: 1.4rem;
						height: 1.4rem;
						border-radius: 50%;
						background: var(--surface-contrast-container-lowest);
						cursor: pointer;
						transform: translateY(calc(-50% + 0.1875rem));
					}
				}
			}
		}
		.audio-footer {
			width: 100%;
			.transcription {
				border-top-left-radius: var(--border-radius-m);
				border-top-right-radius: var(--border-radius-m);
				background-color: var(--surface-opacity-high-light);
				padding-inline: var(--padding-l);
				transition: all 0.3s;
				&.active {
					background-color: var(--text-on-secondary-container);
				}
				.transcription-header {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-block: var(--padding-m);
					cursor: pointer;
					color: var(--text-on-surface-standard-opacity-light);
				}
				.transcription-content {
					height: 0;
					overflow: hidden;
					transition: all 0.5s;
					padding-block: 0;
					overflow-y: scroll;
					-ms-overflow-style: none;  /* IE and Edge */
					scrollbar-width: none;  /* Firefox */
					position: relative;
					&::-webkit-scrollbar {
						display: none;
					}
					.transcription-html {
						padding-bottom: 10vh;
					}
					// add gradient on bottom
				}
				&:after {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					pointer-events: none;
					height: 20vh;
					background: linear-gradient(0deg, black, transparent);
					opacity: 0;
					transition: opacity 0.5s;
				}
				&.active {
					.transcription-content {
						padding-block: var(--padding-l);
						height: 30vh;
					}
					&:after {
						opacity: 1;
					}
				}
			}
		
		}
	}
</style>
<style lang="scss">
.audio-content {
	.actions {
		.play-button {
			[class^="icon-"] {
				font-size: var(--icon-size-xl);
			}
		}
	}
}
</style>

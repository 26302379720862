<template>
	<button class="artwork-card" :class="status" @click="openArtwork(artwork)" aria-label="Open artwork" tabindex="0">
		<img class="image" :style="style" :src="image" :alt="title"/>
		<div class="bottom-container">
			<h3 class="artwork-title body-small-semibold">{{ title }}</h3>
			<div v-if="status !== 'preview'" class="container-info-chips">
				<div class="info-icon">
					<MaoChipIconText icon="stairs" :label="floor" mode="dark" />
					<MaoChipIconText v-for="(icon, index) in iconList" :key="index" :icon="icon.icon" mode="dark" />
				</div>
			</div>
		</div>
	</button>
</template>

<script>
	import MaoChipIconText from "./MaoChipIconText.vue";
	import { useAppDataStore } from "@/stores/appData";

	export default {
		components: {
			MaoChipIconText
		},
		props: [
			'title',
			'image',
			'floor',
			'status',
			'iconList',
			'imagePointView',
			'artwork'
		],
		setup() {
			const appDataStore = useAppDataStore();
			return { appDataStore };
		},
		methods: {
			openArtwork(artwork) {
				
				this.$router.push({name: 'Artwork', params: {id: artwork.id, lang: this.$route.params.lang }});
			}
		},
		computed: {
			labels() {
				return this.appDataStore.appData.labels;
			},
			style() {
				if(this.imagePointView) {
					return `object-position: ${this.imagePointView['left']}% ${this.imagePointView['top']}%;`;
				}
				return '';
			},
		}
	};
</script>

<style scoped lang="scss">

	.artwork-card {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
		border-radius: var(--border-radius-m);
		background: var(--surface-opacity-high-dark);
		transition: background 0.3s, border-radius 0.3s;
		cursor: pointer;

		@include generateCardStates(
			(
				enabled: (
					background: var(--surface-opacity-high-dark)
				),
				hover: (
					background: var(--surface-opacity-standard-dark)
				),
				pressed: (
					background: var(--surface-opacity-standard-dark),
					border-radius: var(--border-radius-l)
				),
				preview: (
					height: fit-content
				)
			)
		);

		/* &.enabled,
		&.pressed,
		&.hover {
			.image {
				aspect-ratio: 23 / 29;
			}
		}

		&.preview {
			.image {
				aspect-ratio: 1;
			}
		} */

		.image {
			border-radius: var(--border-radius-m);
			transition: border-radius 0.3s;
			object-fit: cover;
			object-position: center center;
			width: 100%;
			aspect-ratio: 4/5;
		}

		.bottom-container {
			display: flex;
			padding: var(--padding-s) var(--padding-s) var(--padding-l) var(--padding-s);
			flex-direction: column;
			align-items: flex-start;
			gap: var(--margin-xs);
			align-self: stretch;
			border-radius: var(--border-radius-m);

			.artwork-title {
				display: inline-block;
				word-wrap: break-word;
				text-align: left !important;
				color: black !important;
			}

			.container-info-chips {
				display: flex;
				justify-content: space-between;
				align-items: center;
				align-content: center;
				row-gap: 0.25rem;
				align-self: stretch;
				flex-wrap: wrap;
			}
		}
	}
</style>

<template>
	<div class="intro" :class="{['loading-'+[loading]]: true }" v-if="loading" aria-busy="true" aria-live="polite"> 
		<div class="logo"> 
			<svg class="mao" viewBox="0 0 31 100" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M0.0639648 0.506836H30.6703V30.9026H24.6177L24.6177 6.63557L18.4965 6.63557L18.4965 30.9026H12.3754L12.3754 6.63557L6.25409 6.63557L6.25408 30.9026H0.0639648V0.506836Z" fill="white"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M0.0639648 34.8022H30.6703V65.198H0.0639648V34.8022ZM0.0640078 40.8141H24.5491V46.8933H0.0640078V40.8141ZM24.6177 53.0385H6.25385V59.1177H24.6177V53.0385Z" fill="white"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M0.0639648 69.0977H30.9359V99.4934H0.0639648V69.0977ZM6.16922 75.1093H24.8309V93.4831H6.16922V75.1093ZM18.6565 81.1868H12.3436V87.4023H18.6565V81.1868Z" fill="white"/>
			</svg>

			<svg class="app" viewBox="0 0 65 100" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M0.00976562 34.8022H30.6161V53.0385H6.19969V65.1968H30.6161V65.198H0.00976562V34.8022ZM6.19969 40.8141H24.5635V46.8933H6.19969V40.8141Z" fill="white" fill-opacity="0.7"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M33.6489 34.8022H64.2553V53.0385H39.839V65.1968H64.2553V65.198H33.6489V34.8022ZM39.839 40.8141H58.2028V46.8933H39.839V40.8141Z" fill="white" fill-opacity="0.7"/>
			</svg>
		</div>
		<div class="loader"></div>
	</div>
</template>

<script>
	import { defineComponent } from "vue";
	import { useAppDataStore } from "@/stores/appData";

	export default defineComponent({
		setup() {
			const appDataStore = useAppDataStore();
			return { appDataStore };
		},
		data() {
			return {
				loading: 0,
				firstLoad: true
			}
		},
		watch: {
			"appDataStore.appData.lang": {
				handler: function() {
					if(!this.firstLoad && this.appDataStore.appData.lang) {
						this.loadData(this.appDataStore.appData.lang);
					}
				}
			}
		},
		methods: {
			async loadData(lang = 'it') {
				lang;
				this.appDataStore.setAppDataLoaded(false);
				this.loading = 1;

				const currentLang = window.location.pathname.split('/')[1];
				
				await this.appDataStore.setLabels(currentLang);
				await this.appDataStore.getAppData(currentLang);
				this.loading = 2;
				await new Promise((resolve) => setTimeout(resolve, 100));
				this.loading = 0;
				this.$emit("onLoaded");
				this.appDataStore.setAppDataLoaded(true);
				return true;
			}
		},
		async mounted() {
			await this.appDataStore.getLanguages();
			this.firstLoad = true;
			if(!this.appDataStore.appData.loaded) {
				await this.loadData('it');
				this.firstLoad = false;
			}
		}
	});
</script>

<style scoped lang="scss">
.intro {
	$logo-size: 8rem;
	position: fixed;
	inset: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--surface-primary-standard);
	color: #d9d9d9;
	transition: all 0.5s;
	opacity: 0;
	pointer-events: none;
	z-index: 13;

	.logo {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: $logo-size;
		.mao {
			position: absolute;
			background: var(--surface-primary-standard);
			transition: all 1s;
			height: 100%;
			left: 50%;
			transform: translateX(-50%);
		}
	
		.app {
			opacity: 0;
			transition: opacity 1s ease-in-out, transform 1s ease-in-out;
			height: 100%;
		}
	}

	&.loading-1 {
		opacity: 1;
		pointer-events: all;
		.loader {
			opacity: 1;
		}
	}

	.loader {
		aspect-ratio: 1;
		position: absolute;
		bottom: 0;
		left: 0;
		height: 0.5rem;
		display: block;
		background-color: white;
		animation: slide 3s linear infinite;
		opacity: 0;
		transition: all 1s;
		pointer-events: none;
	}

	@keyframes slide {
		0% {
			left: 0;
			width: 0%;
		}
		50% {
			left: 0;
			width: 100%;
		}
		100% {
			left: 100%;
			width: 0%;
		}
	}

	
	&.loading-0,
	&.loading-2 {
		&.loading-2 {
			opacity: 1;
			pointer-events: all;
		}

		.mao {
			transform: translateX(-4rem);
		}
		.app {
			opacity: 1;
			transform: translateX(1.4rem);
		}
	}
}
</style>
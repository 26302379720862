<template>
	<div class="footer" :class="{loaded: loaded}">
		<div class="grid-x">
			<div class="copyright col-1-start col-12-end label-big	">
				{{labels['© 2024 Museum of Oriental Art of Turin.']}}<br>
				{{labels['All rights reserved.']}}
			</div>
		</div>
	</div>
</template>

<script>
import { useAppDataStore } from "@/stores/appData";

export default {
	setup() {
		const appDataStore = useAppDataStore();
		return { appDataStore };
	},
	computed: {
		labels() {
			return this.appDataStore.appData.labels;
		},
		// computed on appData deep, for reactive changes on loaded
		loaded() {
			return this.appDataStore.appData.loaded;
		},
	},
};
</script>

<style scoped lang="scss">
.footer {
	position: relative;
	padding: var(--padding-xl) 0;
	background-color: var(--surface-contrast-container-high);
	color: var(--text-on-surface-invert);
	opacity: 0;
	pointer-events: none;
	transition: all 0.3s;
	
	&.loaded {
		opacity: 1;
		pointer-events: all;
	}

}
</style>
<template>
	<Teleport to="body">
		<transition name="fade">
			<div v-if="status">
				<div class="alert-modal-container grid-x">
					<div class="alert-modal" :style="style" :class="{ active: status }" aria-label="Modal" role="alert" tabindex="10">
						<h2 :style="style" v-if="title" class="text body-big-semibold">{{ title }}</h2>
						<MaoButton variant="text-button-opacity-light" icon="close" @close="emitClose" />
						<slot></slot>
					</div>
				</div>
				<MaoOverlay :status="status" @close="emitClose" />
			</div>
		</transition>
	</Teleport>
</template>

<script>
	import MaoButton from "./MaoButton.vue";
	import MaoOverlay from "./MaoOverlay.vue";

	export default {
		emits: ["close"],
		components: {
			MaoOverlay,
			MaoButton
		},
		computed: {
			style() {
				return {
					background: this.background,
					color: this.color
				};
			}
		},
		props: ['status','title','background','color'],
		methods: {
			emitClose() {
				this.$emit("close");
			}
		}
	};
</script>

<style scoped lang="scss">
	.alert-modal-container {
		position: fixed;
		display: inline-grid;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 3;
		width: 100%;
		max-width: 600px;
		
		.alert-modal {
			display: flex;
			padding: var(--padding-l);
			flex-direction: column;
			gap: var(--gap-m);
			border-radius: var(--border-radius-m);
			background: var(--surface-alert-container);
			transition: all 0.3s;
			grid-column: main-column;

			& > .button {
				position: absolute;
				top: 0rem;
				right: 1rem;
				transform: translateY(-100%);
			}

			&.active {
				.alert-modal {
					animation: zoomInFadeIn 0.5s ease-out forwards;
				}
			}

			h2 {
				font-size: 1rem;
			}

			.text {
				color: var(--text-on-alert-container);
			}

			.buttons {
				display: flex;
				gap: var(--gap-s);
				align-self: stretch;
			}
		}
	}
</style>


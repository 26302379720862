<template>
	<div class="video-button" v-if="video" :style="'background-image: url('+video.thumbnail+')'" @click="openAlert" @mousedown="onPress" @mouseup="onRelease">
		<MaoButton variant="secondary" icon="play_arrow" :status="pressed? 'pressed' : ''" />
		<div class="duration label-big"><span class="icon-smart_display"></span>{{ video.duration }}</div>
	</div>
	<MaoAlert :status="alertStatus" @close="closeAlert">
		{{ appDataStore.appData.labels["Per un'esperienza ottimale, si consiglia l'utilizzo di cuffie o di moderare il volume per non disturbare chi è vicino. Grazie per la collaborazione."] }}
		<div class="buttons">
			<MaoButton @open="openAudioPlayer" variant="alert" label="Avvia video" />
		</div>
	</MaoAlert>
	<MaoVideoPlayer :title="title" :video="video" :status="videoPlayer" @close="closeVideoPlayer" />
</template>

<script>
import { useGlobalActionsStore } from "@/stores/globalActions";
import { useAppDataStore } from "@/stores/appData";
import MaoButton from "./MaoButton.vue";
import MaoAlert from "./MaoAlert.vue";
import MaoVideoPlayer from "./MaoVideoPlayer.vue";

export default {
	components: {
		MaoButton,
		MaoAlert,
		MaoVideoPlayer
	},
	props: {
		title: {
			type: Object,
			required: true,
		},
		video: {
			type: Object,
			required: true,
		},
	},
	setup() {
		const appDataStore = useAppDataStore();
		const globalActionsStore = useGlobalActionsStore();
		return { appDataStore, globalActionsStore };
	},
	data() {
		return {
			pressed: false,
			alertStatus: false,
			videoPlayer: false
		}
	},
	methods: {
		closeAlert() {
			this.alertStatus = false;
			this.globalActionsStore.setPreventScrolling(false);
		},
		onPress() {
			this.pressed = true;
		},
		onRelease() {
			this.pressed = false;
		},
		openAlert() {
			this.alertStatus = true;
			this.globalActionsStore.setPreventScrolling(true);
		},
		closeVideoPlayer() {
			this.videoPlayer = false;
			this.globalActionsStore.setPreventScrolling(false);
		},
		openAudioPlayer() {
			this.alertStatus = false;
			this.videoPlayer = true;
		},
	},
};
</script>

<style scoped lang="scss">
.buttons {
	display: flex;
	gap: var(--gap-s);
	justify-content: stretch;
	.button {
		justify-content: center;
		width: 100%;
	}
}
.video-button {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	align-items: center;
	border-radius: var(--border-radius-m);
	padding: 7rem 0;
	@include breakpoint(s) {
		padding: 3rem 0;
	}
	
	background-color: var(--surface-background-variant-dark);
    background-size: cover;
	background-position: center center;
	position: relative;
	transition: all 0.3s;
	.button {
		z-index: 1;
	}

	@include generateCardStates((
		pressed: (
			background-color: var(--surface-contrast-container-high),
			border-radius: var(--border-radius-l),
		)
	));

	.duration {
		position: absolute;
		bottom: 0.5rem;
		left: 1rem;
		display: flex;
		gap: 0.5rem;
		align-items: center;
	}

	&::before {
		content: '';
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		background: black;
		opacity: 0.5;
	}


}
</style>

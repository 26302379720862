import { defineStore } from "pinia";

export const useZoomStore = defineStore("useZoomStore", {
	// State
	state: () => ({
		zoom: {
			zoomImageUrl: '',
		}
	}),

	actions: {
		setZoomImage(zoomImageUrl) {
			this.zoom.zoomImageUrl = zoomImageUrl;
		},
	}
});

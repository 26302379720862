<template>
	<button :class="buttonClasses" v-bind="accessibilityAttrs" @click="emitClick" @keydown.enter="emitClick"
		@keydown.space="emitClick" role="button">
		<div v-if="icon" :class="{ ['icon-' + icon]: true, [childClasses]: true }" aria-hidden="true"></div>
		<div v-if="label" class="body-small" :class="childClasses">{{ label }}</div>
	</button>
</template>


<script>
export default {
	emits: [
		"click",
		"open",
		"close",
		"play",
		"pause",
		"cancel",
		"delete",
		"add",
		"remove",
		"forward",
		"replay",
		"increment",
		"decrement",
		"select",
		"toggle"
	],
	props: {
		tabindex: {
			default: 0
		},
		status: {
			type: String,
			default: ""
		},
		icon: {
			type: String,
			default: ""
		},
		class: {
			type: String,
			default: ""
		},
		label: {
			type: String,
			default: ""
		},
		outline: {
			type: Boolean,
			default: false
		},
		variant: {
			type: String,
			default: ""
		},
		fullwidth: {
			type: Boolean,
			default: false
		},
		ariaHidden: {
		}
	},
	methods: {
		emitClick() {
			const emits = [
				"click",
				"open",
				"close",
				"play",
				"pause",
				"cancel",
				"delete",
				"add",
				"remove",
				"forward",
				"replay",
				"increment",
				"decrement",
				"select",
				"toggle"
			];
			emits.forEach(emit => {
				this.$emit(emit);
			});
		}
	},
	computed: {
		accessibilityAttrs() {
			if (this.ariaHidden) {
				return {
					tabindex: -1,
				};
			}
			else {
				return {
					role: "button",
					tabindex: this.tabindex,
					"aria-label": this.label ? this.label : this.icon ? `Button with icon ${this.icon}` : "Button",
				};
			}
		},
		childClasses() {
			return this.class;
		},
		buttonClasses() {
			const classes = [
				this.outline ? "outline" : "",
				this.status,
				this.variant,
				this.fullwidth ? "fullwidth" : "",
				"button",
				this.class,
				this.label ? 'has-text' : '',
				this.icon ? 'button-' + this.icon : ''
			]
				.filter(Boolean)
				.join(" ");

			return classes;
		}
	}
};
</script>

<style lang="scss" scoped>
.button {
	transition: all 0.3s;
	display: inline-flex;
	align-items: center;
	padding: var(--padding-s);
	gap: var(--gap-s);
	border-radius: var(--border-radius-xs);
	background: var(--surface-primary-standard);
	color: var(--text-on-primary-standard);
	cursor: pointer;

	@include generateButtonStates((hover: (background: var(--surface-primary-pressed)),
			pressed: (background: var(--surface-primary-pressed),
				border-radius: var(--border-radius-s)),
			toggled: (background: var(--surface-primary-container),
				border-radius: var(--border-radius-s))));

	&.secondary {
		background: var(--surface-secondary-standard);
		@include generateButtonStates((hover: (background: var(--surface-secondary-pressed)),
				pressed: (background: var(--surface-secondary-pressed)),
				toggled: (background: var(--text-on-secondary-container),
					color: var(--surface-secondary-standard))));
	}

	&.alert {
		background: var(--surface-alert-standard);
		color: var(--text-on-alert-standard);

		@include generateButtonStates((hover: (background: var(--surface-alert-pressed)),
				toggled: (background: var(--surface-alert-container),
					color: var(--text-on-alert-container)),
				pressed: (background: var(--surface-alert-pressed))));
	}

	&.primary-alternative {
		background: var(--surface-opacity-high-dark);
		color: var(--text-on-surface-variant-high);

		@include generateButtonStates((hover: (color: var(--text-on-primary-standard)),
				pressed: (color: var(--text-on-primary-standard)),
				toggled: (background: var(--surface-primary-container),
					color: var(--text-on-primary-container),
					border-radius: var(--border-radius-xs))));
	}

	&.opacity-light {
		background: var(--surface-opacity-high-light);
		color: var(--text-on-surface-invert);

		@include generateButtonStates((pressed: (background: var(--surface-opacity-low-light),
					color: var(--text-on-surface-invert))));
	}

	&.opacity-dark {
		background: var(--surface-opacity-high-dark);
		color: var(--text-on-surface-standard);

		@include generateButtonStates((hover: (color: var(--text-on-primary-standard)),
				toggled: (background: var(--surface-opacity-low-light),
					color: var(--surface-opacity-standard-dark)),
				pressed: (background: var(--surface-opacity-low-dark),
					color: var(--text-on-surface-standard))));
	}

	&.text-button {
		background: transparent;
		color: var(--text-on-surface-standard);
		@include generateButtonStates((hover: (background: var(--surface-opacity-high-dark)),
				pressed: (background: var(--surface-opacity-high-dark))));
	}

	&.text-button-opacity-light {
		background: transparent;
		color: var(--text-on-surface-invert);
		@include generateButtonStates((hover: (background: var(--surface-opacity-high-light)),
				pressed: (background: var(--surface-opacity-high-light))));
	}

	&.text-button-opacity-dark {
		background: transparent;
		color: var(--text-on-surface-standard);
		@include generateButtonStates((hover: (background: var(--surface-opacity-high-dark)),
				pressed: (background: var(--surface-opacity-high-dark))));
	}

	&.outline {
		border: #{$border-size} solid;
		background: transparent;
		border-color: var(--border-stroke-color-secondary);
		color: var(--text-on-secondary-variant);

		@include generateButtonStates((hover: (background: var(--surface-secondary-standard),
					color: var(--text-on-primary-standard)),
				pressed: (border-color: transparent,
					background: var(--surface-secondary-pressed),
					color: var(--text-on-primary-standard)),
				toggled: (background: var(--surface-secondary-container))));

		&.alert {
			border-color: var(--border-stroke-color-alert);
			color: var(--text-on-alert-variant);

			@include generateButtonStates((hover: (background: var(--surface-alert-standard),
						color: var(--text-on-primary-standard)),
					pressed: (background: var(--surface-alert-pressed),
						color: var(--text-on-primary-standard)),
					toggled: (background: var(--surface-alert-container))));
		}
	}

	&.fullwidth {
		justify-content: center;
		width: 100%;
	}

	&.disabled {
		background: var(--surface-opacity-standard-dark);
		color: var(--text-on-surface-variant-low);
		border-color: var(--text-on-surface-variant-low);
		cursor: not-allowed;
	}

	&.standby {
		opacity: 0.15;
		pointer-events: none;
	}
}
</style>

import "./styles/_grid-system.scss";
import "./styles/_grid.scss";
import "./styles/_responsive.scss";
import "./styles/_icomoon.scss";
import "./styles/_typography.scss";
import "./styles/_accessibility.scss";
import "./styles/_animations.scss";
import "./styles/_assets.scss";
import "./styles/_reset.scss";
import "@coddicat/vue-pinch-scroll-zoom/style.css";
import VueStatic from "vue-static";

import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";

const app = createApp(App);

app.use(createPinia());
app.use(VueStatic);
app.use(router);

app.mount("#app");

/* import { createApp } from 'vue'
import App from './App.vue'

createApp(App).mount('#app')
 */

<template>
	<main>
		<div class="home-view" v-if="homeData">
			<div class="backdrop-image" aria-hidden="true" tabindex="-1" v-if="homeData.featured_image" :style="{'background-image': 'url('+homeData.featured_image.large+')'}"></div>
			<MaoImage :fullheight="true" class="home-featured-image" tabindex="-1" v-if="homeData.featured_image" :url="homeData.featured_image.large" />
			<div class="home-content grid-x">
				<section class="main-column" id="main-content">
					<div class="home-description inset body-big" aria-labelledby="sectionTitle">
						<h1 id="sectionTitle" class="path-title inset display-big">{{ homeData["content-title"] }}</h1>
						<div><MaoGlossary :content="homeData.description" /></div> 
						<MaoVideoButton :title="homeData['video-introduction-title']" :video="homeData['video-introduction']" />
					</div>
					<MaoSeparator />
					<div aria-labelledby="currentExhibitionsTitle">
						<div id="currentExhibitionsTitle" class="artworks-title body-big-Semibold inset">
							{{ labels["Discover the current exhibitions at the MAO"] }}
						</div>
						<Flicking :plugins="exhibitionPlugins" :options="{panelsPerView: 1.3, bound: true, align: 'prev'}" class="flick-panel-wrapper" v-if="canFlickingRender">
							<div v-for="(exhibition, index) in homeData.exhibitions" :key="index" class="flicking-panel">
								<MaoCardExhibition :exhibition="exhibition" />
							</div>
							<template #viewport>
								<div class="flicking-pagination dark"></div>
							</template>
						</Flicking>
					</div>
					<MaoSeparator />
					<div aria-labelledby="pathsListTitle">
						<div class="artworks-title body-big-Semibold inset">
							{{ labels["Art Paths: What's New from the MAO"] }}
						</div>
						<section class="paths-list">
							<MaoRouteCard
								v-for="path in paths"
								:key="path.id"
								:title="path.title"
								:path="path"
								:ar="path.types['pattern-file']"
								:imagesMode="path.types['image'] || path.types['image-gallery']"
								:headphones="path.types['audio']"
								:image="path.featured_image"
								:artworksNumber="path.fragments.length"
								:duration="path.duration"
								:sign_language="path.tactile"
								:video="path.types['video']"
							/>
						</section>
					</div>
					<div aria-labelledby="artworksListTitle">
						<h2 id="artworksListTitle" class="artworks-title body-big-Semibold inset">{{ labels["Treasures not to be missed"] }}</h2>
						<div class="flicking-list fullscreen">
							<Flicking :plugins="exhibitionPlugins" :options="{panelsPerView: 1.5, bound: true, align: 'prev'}" v-if="canFlickingRender">
								<div v-for="(artwork, index) in artworks" :key="index" class="flicking-panel">
									<MaoArtworkCard
										:key="artwork.id"
										:image="artwork.featured_image.large"
										:floor="artwork.floor"
										:title="artwork.title"
										:imagePointView="artwork.featured_image['image-point-view']"
										:artwork="artwork"
										/>
								</div>
								<template #viewport>
									<div class="flicking-pagination dark"></div>
								</template>
							</Flicking>
						</div>
						<h3 class="headline-big inset">{{ homeData["title-info"] }}</h3>
						<div class="mao-description inset">
							<div v-html="homeData['info']"></div>
							<MaoVideoButton :title="homeData['video-lis-title']" :video="homeData['video-lis']" />
						</div>
					</div>
				</section>
			</div>
		</div>
	</main>
</template>

<script>
	import Flicking from "@egjs/vue3-flicking";
	import MaoArtworkCard from "@/components/MaoArtworkCard.vue";
	import MaoCardExhibition from "@/components/MaoCardExhibition.vue";
	import MaoImage from "@/components/MaoImage.vue";
	import MaoRouteCard from "@/components/MaoRouteCard.vue";
	import MaoSeparator from "@/components/MaoSeparator.vue";
	import MaoVideoButton from "@/components/MaoVideoButton.vue";
	import MaoGlossary from "@/components/MaoGlossary.vue";
	import { Pagination } from "@egjs/flicking-plugins";
	import { defineComponent } from "vue";
	import { useAppDataStore } from "@/stores/appData.js";
	import { useBottomMenuStore } from "@/stores/bottomMenu.js";
	import { useGlobalActionsStore } from "@/stores/globalActions.js";

	export default defineComponent({
		name: "HomeView",
		components: {
			MaoImage,
			MaoSeparator,
			Flicking,
			MaoCardExhibition,
			MaoRouteCard,
			MaoArtworkCard,
			MaoVideoButton,
			MaoGlossary
		},
		setup() {
			const appDataStore = useAppDataStore();
			const bottomMenuStore = useBottomMenuStore();
			const globalActionsStore = useGlobalActionsStore();
			return { bottomMenuStore, appDataStore, globalActionsStore };
		},
		data() {
			return {
				exhibitionPlugins: [
					new Pagination({
						type: "scroll",
						bulletCount: 3,
					})
				],
				artworksPlugins: [
					new Pagination({
						type: "scroll",
						bulletCount: 3
					})
				],
				canFlickingRender: true,
				canFlickingRenderTimeout: null,
			}
		},
		computed: {
			homeData() {
				return this.appDataStore.appData.pages.home;
			},
			labels() {
				return this.appDataStore.appData.labels;
			},
			paths() {
				let paths = [];
				for (let path of this.homeData.paths) {
					// get from appData.paths the path with the same id
					let pathData = this.appDataStore.appData.paths.filter(el => {
						return el.id === path.id;
					})[0];
					if (pathData) {
						paths.push(this.getPathData(pathData));
					}
				}

				return paths;
			},
			artworks() {
				let artworks = [];
				for (let artwork of this.homeData.artworks) {
					let artworkData = this.getArtworkById(artwork.id);
					if (artworkData) {
						artworks.push(artworkData);
					}
				}

				return artworks;
			}
		},
		mounted() {
			this.bottomMenuStore.setBottomMenu(true);
			this.globalActionsStore.showHeader();
			window.addEventListener("resize", this.onWindowResize);
			this.onWindowResize();
		},
		methods: {
			onWindowResize() {
				this.canFlickingRender = false;
				// wait 100ms and then
				clearTimeout(this.canFlickingRenderTimeout);
				this.canFlickingRenderTimeout = setTimeout(() => {
					this.canFlickingRender = true;
				}, 100);
				// check window size
				if (window.innerWidth < 768) {
					this.optionsExhibitions = {
						panelsPerView: 1,
						bound: true,
						align: "prev"
					};
					this.optionsArtworks = {
						panelsPerView: 1,
						bound: true,
						align: "prev"
					};
				}
				else {
					this.optionsExhibitions = {
						panelsPerView: 2,
						bound: true,
						align: "prev"
					};
					this.optionsArtworks = {
						panelsPerView: 3,
						bound: true,
						align: "prev"
					};
				}
			},
			getPathData(path) {
				let result = path;
				result.types = [];
				result.tactile = false;

				for (let fragment of path.fragments) {
					let fragmentsIds = fragment["fragments-ids"];
					let artwork = this.getArtworkById(fragment["artwork-id"]);
					if (artwork) {
						for (let fragmentData of artwork.fragments) {
							if (fragmentsIds.includes(fragmentData.id)) {
								result.types[fragmentData.type] = true;
							}

							if (artwork.braille) {
								result.tactile = true;
							}
						}
					}
				}

				return result;
			},
			getArtworkById(artworkId) {
				const artworks = this.appDataStore.appData["artworks"];
				for (let artwork of artworks) {
					if (artwork.id == artworkId) {
						return artwork;
					}
				}
				return false;
			}
		}
	});
</script>

<style scoped lang="scss">
	.home-view {
		background: white;
		min-height: 100vh;
		padding-top: 50vh;

		[aria-labelledby="currentExhibitionsTitle"] {
			min-height: 30rem;
		}

		.home-featured-image {
			height: 100vh;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			overflow: visible;
			

			@include breakpoint(s) {
				height: 50vh;
			}
		}

		.path-title {
			color: black;
		}

		.home-description {
			color: var(--text-on-surface-variant-high);
		}

		.artworks-title {
			color: var(--text-on-surface-standard-opacity-dark);
			margin: 1rem 0;
			margin-top: 2rem;
		}

		.artwork-accordion {
			display: flex;
			flex-direction: column;
			gap: var(--gap-m);
		}

		.mao-description {
			margin-bottom: 3rem;
		}

		.flicking-list {
			margin-bottom: 2rem;
		}

		.home-header {
			display: flex;
			gap: var(--gap-m);
			flex-direction: column;

			.container-info-chips {
				display: flex;
				gap: var(--gap-xs);
				flex-wrap: wrap;
			}
		}

		.home-content {
			border-top-left-radius: var(--border-radius-l);
			border-top-right-radius: var(--border-radius-l);
			margin-top: calc(var(--border-radius-l) * -1);
			padding-top: 1rem;
			z-index: 1;
			position: relative;
			background: #fff;
			.home-buttons {
				display: flex;
				gap: var(--gap-s);
			}
		}
	}
</style>

<style lang="scss">
	@import url("@egjs/vue3-flicking/dist/flicking.css");
	@import url("@egjs/flicking-plugins/dist/pagination.css");
	

	/* .flicking-panel {
		width: 100%;
	} */
	@media (max-width: 768px) {
		/* .flicking-panel {
			width: 100%;
		} */
	}

	.home-view {
		.video-button {
			margin: 1rem 0;
		}
		/* .image {
			height: auto;
		} */
	}


</style>
